// Angualar Core
import { Injectable, OnDestroy } from '@angular/core';
import * as CryptoJS from 'crypto-js';

// Models
import { ConfirmationViewType, PrintOrEmailTypes, QueueTypes, AlertType } from 'src/app/models/enums';
import { PaySelectedGroupPoliciesData, PayGroupPoliciesBannerData } from 'src/app/models/classes';
import { Booking, BookingAgentInformation, BookingTypes } from '@allianz/agent-max-core-lib';
import { AgencyGroup, UserInformation, UserOptions, PrintOrEmailOptions, AgentProfile, GDSInterfaceSettings, AddressAndLogo, AgencySettings, DefaultAgentProfile, InterfaceSettings } from 'src/app/models/interfaces';

// Third Party
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Guid } from 'guid-typescript';

@Injectable()
export class AppStateService implements  OnDestroy {
  public displayBannerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public displayBannerObservable$: Observable<boolean> = this.displayBannerSubject.asObservable();
  public isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAdminObservable$: Observable<boolean> = this.isAdminSubject.asObservable();
  public waiverState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isFullAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSuperAdminAccessRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fullAccessObservable$: Observable<boolean> = this.isFullAccess.asObservable();
  public IsConfirmationEmailedToAgent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public groupPurchaseBannerType: BehaviorSubject<AlertType> = new BehaviorSubject(null);
  public notificationCountSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  public notificationCountObservable$: Observable<number> = this.notificationCountSubject.asObservable();
  public maxmailNotificationSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public maxmailNotificationObservable$: Observable<boolean> = this.maxmailNotificationSubject.asObservable();
  public quotemaxNotificationSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public quotemaxNotificationObservable$: Observable<boolean> = this.quotemaxNotificationSubject.asObservable();
  public preexpiringNotificationSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public preexpiringNotificationObservable$: Observable<boolean> = this.preexpiringNotificationSubject.asObservable();
  private allowNotificationsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private interfaceSettingsSubject: Subject<InterfaceSettings> = new Subject<InterfaceSettings>();

  private PAY_GROUP_POLICIES_BANNER_DATA_KEY: string = `PAY_GROUP_POLICIES_BANNER_DATA_KEY`;
  private PAY_SELECTED_GROUP_POLICIES_DATA_KEY: string = `PAY_SELECTED_GROUP_POLICIES_DATA_KEY`;
  private EMAIL_TOKEN_KEY: string = `EMAIL_TOKEN_KEY`;
  private TOKEN_KEY: string = `PARTNER_TOKEN_KEY`;
  private USER_KEY: string = `CURRENT_USER_KEY`;
  private REMEMBER_ME_KEY: string = `REMEMBER_ME_KEY`;
  private REPORTING_RIGHT_KEY: string = `REPORTING_RIGHT_KEY`;
  private PASSPORT_RIGHT_KEY: string = `PASSPORT_RIGHT_KEY`;
  private AGENT_INFO_KEY: string = `AGENT_INFO_KEY`;
  private AGENT_PROFILE_KEY: string = `AGENT_PROFILE_KEY`;
  private QUEUE_KEY: string = `QUEUE_TYPE_KEY`;
  private USER_OPTIONS_KEY: string = `USER_OPTIONS_KEY`;
  private QUOTE_KEY: string = `QUOTE_KEY`;
  private INTERFACE_KEY: string = `INTERFACE_KEY`;
  private BOOKING_ID_KEY: string = `BOOKING_ID_KEY`;
  private AGENCY_GROUP_KEY: string = `AGENCY_GROUP_KEY`;
  private AGENCY_INFORMATION_KEY: string = `AGENCY_INFORMATION_KEY`;
  private URL_TOKEN_KEY: string = `URL_TOKEN_KEY`;
  private GUID_KEY: string = 'GUID_KEY';
  private LATEST_GDS_BOOKING_ID_KEY: string = 'LATEST_GDS_BOOKING_ID_KEY';
  private BOOKING_INFO_VIEW_KEY: string = `BOOKING_INFO_VIEW_KEY`;
  private BOOKING_INFO_SOURCE_KEY: string = `BOOKING_INFO_SOURCE_KEY`;
  private PRINT_OR_EMAIL_REPORT_NAME: string = `PRINT_OR_EMAIL_REPORT_NAME`;
  private PRINT_OR_EMAIL_DATA: string = `PRINT_OR_EMAIL_DATA`;
  private CONFIRMATION_VIEW_TYPE_KEY: string = `CONFIRMATION_VIEW_TYPE_KEY`;
  private STORAGE_TIMESTAMP_KEY: string = `STORAGE_TIMESTAMP_KEY`;
  private NOTIFICATION_COUNT_KEY: string = `NOTIFICATION_COUNT_KEY`;
  private QUEUE_FILTER_TYPE_KEY: string = `QUEUE_FILTER_TYPE_KEY`;
  private AGENCY_SETTINGS_KEY: string = `AGENCY_SETTINGS_Key`;
  private AGENCY_ADDRESS_AND_LOG_KEY: string = `AGENCY_ADDRESS_AND_LOG_Key`;
  private CAN_MANAGE_GROUPS_KEY: string = `CAN_MANAGE_GROUPS_KEY`;
  private DEFAULT_AGENT_SETTINGS_KEY: string = `DEFAULT_AGENT_SETTINGS_KEY`;
  private PAGING_SIZE_KEY: string = `PAGING_SIZE_KEY`;
  private IS_ADMIN_PROFILE: string = `IAPUsdGVkX18604XfjiYfRffMDgyAh0jGEzp3lh1ddaY`;
  private APOLLO_SETTINGS: string = `APOLLO_SETTINGS`;
  private UNIVERSAL_LOCATOR_CODE: string = `UNIVERSAL_LOCATOR_CODE`;
  private readonly key = '06319ded-3f47-bea0-6a6d-e26bb606b6fe';
  
  
  public setAdminStatus(isAdmin: boolean): void {
    const encryptedIsAdmin = CryptoJS.AES.encrypt(isAdmin.toString(), this.key).toString();
    localStorage.setItem(this.IS_ADMIN_PROFILE, encryptedIsAdmin);
    this.isAdminSubject.next(isAdmin);
  }


  private retrieveAdminStatus(): void {
    const encryptedIsAdminStored = localStorage.getItem(this.IS_ADMIN_PROFILE);
    if (encryptedIsAdminStored) {
      const bytes = CryptoJS.AES.decrypt(encryptedIsAdminStored, this.key);
      const decryptedIsAdmin = bytes.toString(CryptoJS.enc.Utf8);
      this.isAdminSubject.next(decryptedIsAdmin === 'true');
    }
  }


  public isAdmin() : boolean {
    this.retrieveAdminStatus();
    this.setAdminStatus(this.isAdminSubject.value)
    return this.isAdminSubject.value;
  }

  public IsFullAccess(): boolean {
    return this.isFullAccess.value;
  }


  
  /**
   * completely clears the application state
   * @returns {void}
   */
  clearAppState(): void {
    const rememberMeInfo = this.getRememberMeInfo();
    localStorage.clear();
    sessionStorage.clear();
    this.setRememberMeInfo(rememberMeInfo);
  }

  /**
   * clears the persistent local storage storage
   * @returns {void}
   */
  clearLocalStorage(): void {
    const rememberMeInfo = this.getRememberMeInfo();
    localStorage.clear();
    this.setRememberMeInfo(rememberMeInfo);
  }

  /**
   * clears the volatile session storage
   * @returns {void}
   */
  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  /**
   * Check the timestamp is still valid
   * @returns {boolean}
   */
  public checkTimeStampisValid(): boolean {
    const timeStamp = JSON.parse(localStorage.getItem(this.STORAGE_TIMESTAMP_KEY));
    const expTime = new Date().getTime() - (1 * 60 * 60 * 1000);
    if (expTime < timeStamp) { // timestamp < 1 hour old
      return true;
    } else if (expTime > timeStamp) { // timestamp > 1 hour old
      return false;
    }
  }

  /**
   * Set local storage timestamp
   * @returns {void}
   */
  public setStorageTimeStamp(): void {
    localStorage.setItem(this.STORAGE_TIMESTAMP_KEY, JSON.stringify(new Date().getTime().toString()));
  }

  /**
   * Sets the local storage value for if the current user can manage groups
   * @param {boolean} canManageGroups
   * @returns {void}
   */
  public setCanManageGroups(canManageGroups: boolean): void {
    localStorage.setItem(this.CAN_MANAGE_GROUPS_KEY, JSON.stringify(canManageGroups));
  }

  /**
   * gets whether the current user can manage groups
   * @returns {boolean}
   */
  public getCanManageGroups(): boolean {
    return JSON.parse(localStorage.getItem(this.CAN_MANAGE_GROUPS_KEY));
  }

  /**
   * Gets the agent profile from local storage
   * @returns {AgentProfile}
   */
  public getAgentProfile(): AgentProfile {
    return JSON.parse(localStorage.getItem(this.AGENT_PROFILE_KEY));
  }

  /**
   * Set the agent profile in local storage
   * @param {AgentProfile} agentProfile
   * @returns {void}
   */
  public setAgentProfile(agentProfile: AgentProfile): void {
    localStorage.setItem(this.AGENT_PROFILE_KEY, JSON.stringify(agentProfile));
  }

  /**
   * retrieves the selected agency information in local storage
   * @returns {AgencyGroup}
   */
  public getAgencyInformation(): AgencyGroup {
    return JSON.parse(sessionStorage.getItem(this.AGENCY_INFORMATION_KEY));
  }

  /**
   * stores the selected agency information in local storage
   * @param agency
   * @returns {void}
   */
  public setAgencyInformation(agency: AgencyGroup): void {
    sessionStorage.setItem(this.AGENCY_INFORMATION_KEY, JSON.stringify(agency));
  }
  /**
   * empties out the selected agency information.  necessary when go ing to the
   * overall agencies page.
   * @returns {void}
   */
  public clearAgencyInformation(): void {
    sessionStorage.removeItem(this.AGENCY_INFORMATION_KEY);
  }
  /*
   * Returns PayGroupPoliciesBannerData in local storage
   * @returns {PayGroupPoliciesBannerData}
   */
  public getPayGroupPoliciesBannerData(): PayGroupPoliciesBannerData {
    return JSON.parse(localStorage.getItem(this.PAY_GROUP_POLICIES_BANNER_DATA_KEY));
  }

  /**
   * Sets PayGroupPoliciesBannerData in local storage
   * @param {PayGroupPoliciesBannerData} payGroupPoliciesBannerData
   * @returns {void}
   */
  public setPayGroupPoliciesBannerData(payGroupPoliciesBannerData: PayGroupPoliciesBannerData): void {
    localStorage.setItem(this.PAY_GROUP_POLICIES_BANNER_DATA_KEY, JSON.stringify(payGroupPoliciesBannerData));
  }

  /**
   * Returns PaySelectedGroupPoliciesData in local storage
   * @returns {PaySelectedGroupPoliciesData}
   */
  public getPaySelectedGroupPoliciesData(): PaySelectedGroupPoliciesData {
    return JSON.parse(localStorage.getItem(this.PAY_SELECTED_GROUP_POLICIES_DATA_KEY));
  }

  /**
   * Sets PaySelectedGroupPoliciesData in local storage
   * @param {PaySelectedGroupPoliciesData} paySelectedGroupPoliciesData
   * @returns {void}
   */
  public setPaySelectedGroupPoliciesData(paySelectedGroupPoliciesData: PaySelectedGroupPoliciesData): void {
    localStorage.setItem(this.PAY_SELECTED_GROUP_POLICIES_DATA_KEY, JSON.stringify(paySelectedGroupPoliciesData));
  }

  /**
   * Gets the email token from local storage
   * @returns {string}
   */
  public getEmailToken(): string {
    return JSON.parse(localStorage.getItem(this.EMAIL_TOKEN_KEY));
  }

  /**
   * Sets the email token to local storage
   * @param {string} emailToken
   * @returns {void}
   */
  public setEmailToken(emailToken: string): void {
    localStorage.setItem(this.EMAIL_TOKEN_KEY, JSON.stringify(emailToken));
  }

  /**
   * Gets agent information
   * @returns {BookingAgentInformation}
   */
  public getAgentInformation(): BookingAgentInformation {
    return JSON.parse(localStorage.getItem(this.AGENT_INFO_KEY));
  }

  /**
   * Sets the agents information to persist across application
   * @param {BookingAgentInformation} agent
   * @returns {void}
   */
  public setAgentInformation(agent: BookingAgentInformation): void {
    localStorage.setItem(this.AGENT_INFO_KEY, JSON.stringify(agent));
  }

  /**
   * Gets agent information
   * @returns {UserOptions}
   */
  public getUserOptions(): UserOptions {
    return localStorage.getItem(this.USER_OPTIONS_KEY) !== 'undefined' ? JSON.parse(localStorage.getItem(this.USER_OPTIONS_KEY)) : null;
  }

  /**
   * Sets the agents information to persist across application
   * @param {UserOptions} userOptions
   * @returns {void}
   */
  public setUserOptions(userOptions: UserOptions): void {
    localStorage.setItem(this.USER_OPTIONS_KEY, JSON.stringify(userOptions));
  }

  /**
   * Sets a boolean value of whether to display the header/banner into a subject
   *
   * @param {boolean} displayBanner
   * @returns {void}
   */
  public setBannerDisplayStatus(displayBanner: boolean): void {
    this.displayBannerSubject.next(displayBanner);
  }
  /**
   * Sets a boolean value of whether to allow notifications into a subject
   *
   * @param {boolean} displayBanner
   * @returns {void}
   */
  public setAllowNotifications(isAllowed: boolean): void {
    this.allowNotificationsSubject.next(isAllowed);
  }
  /**
   * gets a boolean value of whether to allow notifications into a subject
   *
   * @param {boolean} displayBanner
   * @returns {void}
   */
  public getAllowNotifications(): BehaviorSubject<boolean> {
    return this.allowNotificationsSubject;
  }


  public setIsConfirmationEmailedToAgent(IsConfirmationEmailedToAgent: boolean): void {
    this.IsConfirmationEmailedToAgent.next(IsConfirmationEmailedToAgent);
  }

  public getIsConfirmationEmailedToAgent(): boolean {
    var item = JSON.parse(localStorage.getItem(this.USER_KEY))

    if(item != null && item != undefined && item.IsConfirmationEmailedToAgent!=undefined){
      return item.IsConfirmationEmailedToAgent;
    }
    return false;
  }

  /**
   * set user Interface Settings
   * @param {InterfaceSettings} interfaceSettings
   * @returns {void}
   */
  public setInterfaceSettings(interfaceSettings: InterfaceSettings): void {
    this.interfaceSettingsSubject.next(interfaceSettings);
  }
  /**
   * get subject of user Interface Settings
   * @returns {Subject<InterfaceSettings>}
   */
  public getInterfaceSettingsSubject(): Subject<InterfaceSettings> {
    return this.interfaceSettingsSubject;
  }

  public setFullAccess(fullAccess: boolean): void {
    this.isFullAccess.next(fullAccess);
  }

  public setIsSuperAdmin(IsSuperAdmin: boolean): void {
    this.isSuperAdminAccessRequest.next(IsSuperAdmin);
  }

  public IsSuperAdmin(): boolean {
    const data =  localStorage.getItem(this.USER_KEY) !== 'undefined' ? JSON.parse(localStorage.getItem(this.USER_KEY)) : null;
    if (data != null && data.IsRequestSuperAdminAccess!=undefined && data.IsRequestSuperAdminAccess)
    {
      return true;
    }
      return false;
  }

  /**
   * Sets the GUID in local storage
   * @param {string} guid
   * @returns {void}
   */
  public setGUID(guid: string): void {
    localStorage.setItem(this.GUID_KEY, guid);
  }

  /**
   * Get GUID from local storage
   * @returns {string}
   */
  public getGUID(): string {
    return localStorage.getItem(this.GUID_KEY);
  }

  /**
   * Sets the Latest GDS BookingId in local storage
   * @param {string} bookingId
   * @returns {void}
   */
  public setLatestGDSBookingId(bookingId: string): void {
    localStorage.setItem(this.LATEST_GDS_BOOKING_ID_KEY, bookingId);
  }

  /**
   * Gets the Latest GDS BookingId in local storage
   * @returns {string}
   */
  public getLatestGDSBookingId(): string {
    return localStorage.getItem(this.LATEST_GDS_BOOKING_ID_KEY);
  }

  /**
   * Sets the reporting right in local storage
   * @param {boolean} userInputData
   * @returns {void}
   */
  public setReportingRight(userInputData: boolean): void {
    localStorage.setItem(this.REPORTING_RIGHT_KEY, JSON.stringify(userInputData));
  }

  /**
   * Get reporting right from local storage
   * @returns {boolean}
   */
  public getReportingRight(): boolean {
    return JSON.parse(localStorage.getItem(this.REPORTING_RIGHT_KEY));
  }

  /**
   * Set passport right at login
   * @param {boolean} userInputData
   * @returns {void}
   */
  public setPassportRight(userInputData: boolean): void {
    localStorage.setItem(this.PASSPORT_RIGHT_KEY, JSON.stringify(userInputData));
  }

  /**
   * Get passport right from local storage
   * @returns {boolean}
   */
  public getPassportRight(): boolean {
    return JSON.parse(localStorage.getItem(this.PASSPORT_RIGHT_KEY));
  }

  /**
   * Sets username for remember me into local storage
   * @param {string} username
   * @returns {void}
   */
  public setRememberMeInfo(username: string): void {
    localStorage.setItem(this.REMEMBER_ME_KEY, JSON.stringify(username));
  }

  /**
   * Get username for remember me
   * @returns {string}
   */
  public getRememberMeInfo(): string {
    return JSON.parse(localStorage.getItem(this.REMEMBER_ME_KEY));
  }

  /**
   * Sets the user credentials
   * @param {UserInformation} userData
   * @returns {void}
   */
  public setCredentials(userData: UserInformation): void {    
    localStorage.setItem(this.USER_KEY, JSON.stringify(userData));
  }

  /**
   * Gets the user information
   * @returns {UserInformation}
   */
  public getCredentials(): UserInformation {
    return JSON.parse(localStorage.getItem(this.USER_KEY));
  }
  /**
   * Sets the agency address and logo for that agency
   * @param {AddressAndLogo} addressAndLogo
   * @returns {void}
   */
  public setAgencyAddressAndLogo(addressAndLogo: AddressAndLogo, agencyId: number): void {
    const _addressAndLogo = this.getAgencyAddressAndLogObject();
    if (_addressAndLogo) {
      _addressAndLogo[agencyId] = addressAndLogo;
      localStorage.setItem(this.AGENCY_ADDRESS_AND_LOG_KEY, JSON.stringify(_addressAndLogo));
    } else {
      const _newAddressAndLogo: { [key: number]: AddressAndLogo; } = {
        [agencyId]: addressAndLogo
      };
      localStorage.setItem(this.AGENCY_SETTINGS_KEY, JSON.stringify(_newAddressAndLogo));
    }
  }

  /**
   * Gets the agency address and logo if exists
   * @param {AgencyId} agencyId
   * @returns {AddressAndLogo}
   */
  public getAgencyAddressAndLogo(agencyId: number): AddressAndLogo | undefined {
    const addressAndLog = this.getAgencyAddressAndLogObject();
    return addressAndLog ? addressAndLog[agencyId] : undefined;
  }
  /**
   * Sets the Agency Settings  for that agency
   * @param {AgencySettings} agencySettings
   * @returns {void}
   */
  public setAgencySettings(agencySettings: AgencySettings, agencyId: number): void {
    const _agencySettings = this.getAgencySettingsObject();
    if (_agencySettings) {
      _agencySettings[agencyId] = agencySettings;
      localStorage.setItem(this.AGENCY_SETTINGS_KEY, JSON.stringify(_agencySettings));
    } else {
      const _newAgencySettings: { [key: number]: AgencySettings; } = {
        [agencyId]: agencySettings
      };
      localStorage.setItem(this.AGENCY_SETTINGS_KEY, JSON.stringify(_newAgencySettings));
    }
  }

  /**
   * Gets  Agency Settings if exists
   * @param {AgencyId} agencyId
   * @returns {AddressAndLogo}
   */
  public getAgencySettings(agencyId: number): AgencySettings | undefined {
    const agencySettings = this.getAgencySettingsObject();
    return agencySettings ? agencySettings[agencyId] : undefined;
  }

  /**
   * Sets the queue type in session storage
   * @param {QueueTypes} queueType
   * @returns {void}
   */
  public setQueueType(queueType: QueueTypes): void {
    localStorage.setItem(this.QUEUE_KEY, JSON.stringify(queueType));
  }

  /**
   * Gets the queue type from local storage
   * @returns {QueueTypes}
   */
  public getQueueType(): QueueTypes {
    return JSON.parse(localStorage.getItem(this.QUEUE_KEY));
  }

  /**
   * Set the queues filter booking filter
   * @param {string} queuesFilterType
   * @returns {void}
   */
  public setQueueBookingFilter(queuesFilterType: string): void {
    localStorage.setItem(this.QUEUE_FILTER_TYPE_KEY, JSON.stringify(queuesFilterType));
  }

  /**
   * Gets the queues filter booking filter
   * @returns {string}
   */
  public getQueueBookingFilter(): string {
    return JSON.parse(localStorage.getItem(this.QUEUE_FILTER_TYPE_KEY));
  }

  /**
   * Clears the user credentials from local storage
   * @returns {void}
   */
  public clearCredentials(): void {
    localStorage.removeItem(this.USER_KEY);
  }

  /**
   * Clears user token from local storage
   * @returns {void}
   */
  public clearUserSessionToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  /**
   * Sets interface settings for admin
   * @param interfaceSettings
   * @returns {void}
   */
  public setCurrentInterfaceSettings(interfaceSettings: GDSInterfaceSettings): void {
    localStorage.setItem(this.INTERFACE_KEY, JSON.stringify(interfaceSettings));
  }

  /**
   * Get current booking quote
   * @returns {GDSInterfaceSettings}
   */
  public getCurrentInterfaceSettings(): GDSInterfaceSettings {
    return JSON.parse(localStorage.getItem(this.INTERFACE_KEY));
  }

  /**
   * Clears the user credentials from session storage
   * @returns {void}
   */
  public clearInterface(): void {
    localStorage.removeItem(this.INTERFACE_KEY);
  }

  /**
   * Sets current booking quote
   * @param {Booking} booking
   * @returns {void}
   */
  public setCurrentBookingQuote(booking: Booking): void {
    localStorage.setItem(this.QUOTE_KEY, JSON.stringify(booking));
  }

  /**
   * Get current booking quote
   * @returns {Booking}
   */
  public getCurrentBookingQuote(): Booking {
    return JSON.parse(localStorage.getItem(this.QUOTE_KEY));
  }

  /**
   * Sets current bookingId
   * @param {string | number} bookingId
   * @returns {void}
   */
  public setCurrentBookingId(bookingId: string | number): void {
    localStorage.setItem(this.BOOKING_ID_KEY, JSON.stringify(bookingId));
  }
  /**
   * Gets current bookingId
   * @returns {string}
   */
  public getCurrentBookingId(): string {
    return JSON.parse(localStorage.getItem(this.BOOKING_ID_KEY));
  }
  /**
   * Set current infoViewType
   * @param {BookingTypes} infoViewType
   * @returns {void}
   */
  public setBookingInfoViewType(infoViewType: BookingTypes): void {
    localStorage.setItem(this.BOOKING_INFO_VIEW_KEY, JSON.stringify(infoViewType));
  }

  /**
   * Get current infoViewType
   * @returns {BookingTypes}
   */
  public getBookingInfoViewType(): BookingTypes {
    return JSON.parse(localStorage.getItem(this.BOOKING_INFO_VIEW_KEY));
  }

  /**
   * Set current infoSourceType
   * @param {BookingTypes} infoSourceType
   * @returns {void}
   */
  public setBookingInfoSourceType(infoSourceType: BookingTypes): void {
    localStorage.setItem(this.BOOKING_INFO_SOURCE_KEY, JSON.stringify(infoSourceType));
  }

  /**
   * Get current infoSourceType
   * @returns {BookingTypes}
   */
  public getBookingInfoSourceType(): BookingTypes {
    return JSON.parse(localStorage.getItem(this.BOOKING_INFO_SOURCE_KEY));
  }

  /**
   * Set Print or Email page Report Name
   * @param {PrintOrEmailTypes} reportName
   * @returns {void}
   */
  public setPrintOrEmailReportName(reportName: PrintOrEmailTypes): void {
    localStorage.setItem(this.PRINT_OR_EMAIL_REPORT_NAME, JSON.stringify(reportName));
  }

  /**
   * Get Print or Email page Report Name
   * @returns {PrintOrEmailTypes}
   */
  public getPrintOrEmailReportName(): PrintOrEmailTypes {
    return JSON.parse(localStorage.getItem(this.PRINT_OR_EMAIL_REPORT_NAME));
  }

  /**
   * Set Print or Email page Data
   * @param {Booking | string} bookingData
   * @param {PrintOrEmailOptions} options
   * @returns {void}
   */
  public setPrintOrEmailData(bookingData: Booking | string, options?: PrintOrEmailOptions): void {
    const emailData: { bookingData: Booking | string, options: PrintOrEmailOptions } = {
      bookingData,
      options
    };
    localStorage.setItem(this.PRINT_OR_EMAIL_DATA, JSON.stringify(emailData));
  }

  /**
   * Get Print or Email page Data
   * @returns { bookingData: Booking | string, options: PrintOrEmailOptions }
   */
  public getPrintOrEmailData(): { bookingData: Booking | string, options: PrintOrEmailOptions } {
    return JSON.parse(localStorage.getItem(this.PRINT_OR_EMAIL_DATA));
  }

  /**
   * This function is used to store data returned from a network call for local
   * networking caching purposes
   * @param {T} item the data to be stored in session storage
   * @param {string} key the key to be used to store the data
   * @returns {void}
   */
  public setCacheFromNetwork<T>(item: T, key: string): void {
    sessionStorage.setItem(`CACHE_${key}`, JSON.stringify(item));
  }

  /**
   * This function is used to retrieve data that has been cached to
   * avoid making excess network calls
   * @param {string} key the key to be used to retrive the data
   * @returns {T}
   */
  public getCacheFromNetwork<T>(key: string): T {
    return JSON.parse(sessionStorage.getItem(`CACHE_${key}`));
  }

  /**
   * Sets the confirmation view type in local storage
   * @param {ConfirmationViewType} confirmationViewType
   */
  public setConfirmationViewType(confirmationViewType: ConfirmationViewType): void {
    localStorage.setItem(this.CONFIRMATION_VIEW_TYPE_KEY, JSON.stringify(confirmationViewType));
  }

  /**
   * gets the confirmation view type from local stoarge
   * @returns {ConfirmationViewType}
   */
  public getConfirmationViewType(): ConfirmationViewType {
    return JSON.parse(localStorage.getItem(this.CONFIRMATION_VIEW_TYPE_KEY));
  }

  /**
   * Sets a boolean value of whether to activate the waiver route in the header
   * @param {boolean} isWaiver
   * @returns {void}
   */
  public triggerWaiverRouteStatus(isWaiver: boolean): void {
    return this.waiverState.next(isWaiver);
  }
  /**
   * Sets the agency group in local storage
   * @param {AgencyGroup[]} agencyGroups A group of agencies that are apart of a specific agency
   */
  public setAgencyGroups(agencyGroups: AgencyGroup[]): void {
    localStorage.setItem(this.AGENCY_GROUP_KEY, JSON.stringify(agencyGroups));
  }
  /**
   * Gets the agency group from local storage
   * @returns {AgencyGroup[]} A group of agencies that are apart of a specific agency
   */
  public getAgencyGroups(): AgencyGroup[] {
    return JSON.parse(localStorage.getItem(this.AGENCY_GROUP_KEY));
  }

  /**
   * Sets a group purchase banner type
   * @param {GroupPurchaseBannerType} bannerType
   * @returns {void}
   */
  public triggerGroupPurchaseBannerType(bannerType: AlertType): void {
    return this.groupPurchaseBannerType.next(bannerType);
  }
  /*
   * Toggles notification count status for header component and home page, retrieve using the public observable
   * @param {number} count
   * @returns {void}
   */
  public triggerNotificationCountStatus(count: number): void {
    if (count != null) {
      this.setNotificationCountStatus(count);
    }
    return this.notificationCountSubject.next(this.getNotificationCountStatus());
  }

  /**
   * Sets the notification count in local storage
   * @param {number} count
   * @returns {void}
   */
  public setNotificationCountStatus(count: number): void {
    localStorage.setItem(this.NOTIFICATION_COUNT_KEY, JSON.stringify(count));
  }

  /**
   * gets the notification count from local stoarge
   * @returns {number}
   */
  public getNotificationCountStatus(): number {
    return JSON.parse(localStorage.getItem(this.NOTIFICATION_COUNT_KEY));
  }

  /**
   * Sets a boolean value of whether to activate the maxmail notifications are unread
   * @param {boolean} unread
   * @returns {void}
   */
  public triggerMaxmailNotificationStatus(unread: boolean): void {
    return this.maxmailNotificationSubject.next(unread);
  }

  /**
   * Sets a boolean value of whether to activate the maxmail notifications are unread
   * @param {boolean} unread
   * @returns {void}
   */
  public triggerQuoteMaxNotificationStatus(unread: boolean): void {
    return this.quotemaxNotificationSubject.next(unread);
  }

  /**
   * Sets a boolean value of whether to activate the maxmail notifications are unread
   * @param {boolean} unread
   * @returns {void}
   */
  public triggerPreexpiringNotificationStatus(unread: boolean): void {
    return this.preexpiringNotificationSubject.next(unread);
  }

  ngOnDestroy(): void {
    this.groupPurchaseBannerType.unsubscribe();
    this.interfaceSettingsSubject.unsubscribe();
  }

  /**
   * Gets the agency address and logo from local storage
   * @returns {{ [key: number]: AddressAndLogo; }} return agency address and logo object where agency id is key and address and logo is value
   */
  private getAgencyAddressAndLogObject(): { [key: number]: AddressAndLogo; } {
    return JSON.parse(localStorage.getItem(this.AGENCY_ADDRESS_AND_LOG_KEY));
  }
  /**
   * Gets the agency settings from local storage
   * @returns {{ [key: number]: AgencySettings; }} return agency settings object where agency id is key and agency settings is value
   */
  private getAgencySettingsObject(): { [key: number]: AgencySettings; } {
    return JSON.parse(localStorage.getItem(this.AGENCY_SETTINGS_KEY));
  }
  /**
   * Gets default agent settings from local storage
   * @returns {{ [key: number]: DefaultAgentProfile; }} return DefaultAgentProfile object where agency id is key and AgentProfile is value
   */
  private getDefaultAgentSettingsObject(): { [key: number]: DefaultAgentProfile; } {
    return JSON.parse(localStorage.getItem(this.DEFAULT_AGENT_SETTINGS_KEY));
  }

    /**
   * Sets pageSize into local storage
   * @param {string} pageSize
   * @returns {void}
   */
     public setPageSize(pageSize: number): void {
      localStorage.setItem(this.PAGING_SIZE_KEY, JSON.stringify(pageSize));
    }

       /**
   * Sets pageSize into local storage
   * @param {string} pageSize
   * @returns {void}
   */
     public getPageSize(): number {
      return JSON.parse(localStorage.getItem(this.PAGING_SIZE_KEY));
    }

   /**
   * Sets Apollo settings from agency settings
   * @param {boolean} isApolloEnabled
   * @returns {void}
   */
     public setApolloSettings(isApolloEnabled: boolean): void {
      localStorage.setItem(this.APOLLO_SETTINGS, JSON.stringify(isApolloEnabled));
    }

    /**
   * Gets the Apollo settings from local storage
   * @returns {boolean} returns boolean if Apollo is enabled or not
   */
  public getApolloSettings(): boolean {
    return JSON.parse(localStorage.getItem(this.APOLLO_SETTINGS));
  }

  /**
   * Sets Galileo Universal Record Locator Code setting from PNR
   * @param {string} universalRecordLocatorCode
   * @returns {void}
   */
   public setUniversalRecordLocatorCode(universalRecordLocatorCode: string): void {
    localStorage.setItem(this.UNIVERSAL_LOCATOR_CODE, JSON.stringify(universalRecordLocatorCode));
  }

  /**
 * Gets Galileo Universal Record Locator Code setting from PNR
 * @returns {string} returns Universal Record Locator Code string when exists
 */
public getUniversalRecordLocatorCode(): string {
  return JSON.parse(localStorage.getItem(this.UNIVERSAL_LOCATOR_CODE));
}

/**
   * Sets interface settings for admin
   * @param interfaceSettings
   * @returns {void}
   */
 public setCurrentArrayInterfaceSettings(interfaceSettings: GDSInterfaceSettings[]): void {
  localStorage.setItem(this.INTERFACE_KEY, JSON.stringify(interfaceSettings));
}

/**
 * Get current booking quote
 * @returns {GDSInterfaceSettings}
 */
public getCurrentArrayInterfaceSettings(): GDSInterfaceSettings[] {
  return JSON.parse(localStorage.getItem(this.INTERFACE_KEY));
}
}
